<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
    <div class="overflow-hidden">
        <div>
            <IndexHeading/>
        </div>
        <div class="premium-carousel" v-if="premiumProperties">
            <div class="container">
                <div class="float-right">
                    <PremiumPropertyCarousel :properties="premiumProperties"/>
                </div>
            </div>

        </div>
        <div class="container offer-banner" v-bind:class="{'d-none': !premiumProperties}">
            <IndexOfferBanner/>
        </div>
        <div class="container" v-if="promoProducts">
            <div class="marketplace-info-box">
                <div class="marketplace-subtitle">#{{ $t('marketplace') }}</div>
                <div class="marketplace-title d-none d-md-block">{{ $t('furnish') }} <span>{{ $t('cheaper') }}</span>
                    {{ $t('now') }}
                </div>
                <div class="marketplace-title d-md-none">{{ $t('furnish') }}
                    <div>
                        <span>{{ $t('cheaper') }}</span> {{ $t('now') }}
                    </div>
                </div>
                <div>{{ $t('index-marketplace-subtitle') }}</div>
            </div>
            <IndexMarketplaceCarousel v-if="promoProducts" :products="promoProducts" :carouseltype="'promo-products'"/>
            <IndexMarketplaceCarousel v-if="newProducts" :products="newProducts" :carouseltype="'new-products'"/>
        </div>
        <div class="index-blog-container">
            <IndexBlogPost :title=" $t('latest-blog-posts')"/>
        </div>
    </div>
</template>


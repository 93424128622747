import Header from '@/components/Header/HeaderDesktop/Header.vue';
import Button from '@/components/Common/Buttons/Button/Button';
import IndexHeading from '@/views/Index/IndexHeading/IndexHeading';
import PremiumPropertyCarousel from '@/views/Index/PremiumPropertyCarousel/PremiumPropertyCarousel';
import IndexOfferBanner from '@/views/Index/IndexOfferBanner/IndexOfferBanner';
import IndexMarketplaceCarousel from '@/views/Index/IndexMarketplaceCarousel/IndexMarketplaceCarousel';
import IndexBlogPost from '@/views/Index/IndexBlogPost/IndexBlogPost';
import UserService from '../../services/UserService';
import Footer from '@/components/Footer/Footer';

export default {
	name: 'Index',
	components: {
		Footer,
		Header,
		
		Button,
		IndexHeading,
		PremiumPropertyCarousel,
		IndexOfferBanner,
		IndexMarketplaceCarousel,
		IndexBlogPost,
	},
	data() {
		return {
			loaded: false,

			metaTitlePrefix: 'dasdasd.bg - ',
			page: {},
			components: {
				aboutIntro: {
					component: 'Preview',
				},
			},
			premiumProperties: null,
			promoProducts: null,
			newProducts: null,
		};
	},
	async mounted() {
		if (this.$route.query.act) {
			if (this.$route.query.act === 'mcnf') {
				UserService.emailConfirmation(this.$route.query.token);
			} else {
				await this.$store.dispatch('utils/addToken', this.$route.query.token);
				await this.$store.dispatch('utils/openModal', 'passChange');
			}
		}

		this.premiumProperties = await this.$store.dispatch('property/getProperties');

		const resultPromo = await this.$store.dispatch('product/searchProduct', {limit: 10, request: {promo: true} });
		this.promoProducts = resultPromo.data;

		const resultNew = await this.$store.dispatch('product/searchProduct', {limit: 10, request: {new: true} });
		this.newProducts = resultNew.data;
	},
	methods: {},
};
